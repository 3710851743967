import { ref, watch, computed } from '@vue/composition-api'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { getToken } from '@/auth/utils'
import axios from 'axios'
import moment from 'moment'
import VueCookies from 'vue-cookies'

export default function useInvoicesList() {
  const userToken = getToken()
  const startDateRaw = moment().subtract(1, 'months').format('YYYY-MM-DD')
  const endDateRaw = moment().format('YYYY-MM-DD')
  const toast = useToast()
  const refInvoicesListTable = ref(null)
  const tableColumns = [
    { key: 'No' },
    { key: 'Customer', sortable: true },
    { key: 'JobNo', sortable: true },
    { key: 'InvoiceNumber', sortable: true },
    { key: 'InvoiceDate', sortable: true },
    { key: 'Reference', sortable: true },
    { key: 'DPP', sortable: true },
    { key: 'Tax', sortable: true },
    { key: 'GrandTotal', sortable: true },
    { key: 'VoidDate', sortable: true },
  ]
  const perPage = ref(10)
  const totalInvoices = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const startDate = ref(startDateRaw)
  const endDate = ref(endDateRaw)
  const sortBy = ref('id')
  const branchId = ref('')
  const isSortDirDesc = ref(true)
  const roleFilter = ref(null)
  const statusFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refInvoicesListTable.value ? refInvoicesListTable.value.localItems.length : 0
    const startDateIn = moment(startDate.value).format('YYYY-MM-DD')
    const endDateIn = moment(endDate.value).format('YYYY-MM-DD')
    const searchQueryVal = searchQuery.value ? searchQuery.value : ''
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalInvoices.value,
      searchQuery: searchQueryVal,
      branchId: branchId.value,
      DateFrom: startDateIn,
      DateTo: endDateIn,
    }
  })

  const refetchData = () => {
    refInvoicesListTable.value.refresh()
  }

  watch([currentPage, perPage, startDate, endDate, searchQuery, branchId], () => {
    console.log('changes ', branchId)
    refetchData()
  })

  const fetchInvoices = async (ctx, callback) => {
    const userKodeUnitKerja = VueCookies.get('KodeUnitKerja')
    const userRole = VueCookies.get('UserRole')
    const body = {
          DateFrom: dataMeta.value.DateFrom,
          DateTo: dataMeta.value.DateTo,
      }
      console.log(dataMeta.value.searchQuery)
      if (dataMeta.value.searchQuery !== '' && dataMeta.value.searchQuery !== null) {
        body.Filter = dataMeta.value.searchQuery
      }
      console.log('body')
    const headers = {
      'Content-Type': 'application/json',
      'X-Token-Access': `Bearer ${userToken}`,
    }
    axios
      .post('https://api.geolims.com/invoice_service/getvoid', body, { headers })
      .then(response => {
        const tmp = []
        response.data.Payload.map(o => {
          const unitkerja = o.JobNo.toString().substring(0, 3)
          if (dataMeta.value.branchId !== '' && dataMeta.value.branchId !== null) {
            if (dataMeta.value.branchId.toString() === unitkerja.toString()) {
              tmp.push(o)
            }
          } else if (userRole === 'branch') {
            if (userKodeUnitKerja.toString() === unitkerja.toString()) {
              tmp.push(o)
            }
          } else tmp.push(o)
          return o
        })
        totalInvoices.value = tmp.length
        const sliced = tmp.slice(
        (currentPage.value - 1) * perPage.value,
        (currentPage.value) * perPage.value,
      )

        callback(sliced)
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching invoice list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = role => {
    if (role === 'subscriber') return 'primary'
    if (role === 'author') return 'warning'
    if (role === 'maintainer') return 'success'
    if (role === 'editor') return 'info'
    if (role === 'super-admin') return 'danger'
    return 'primary'
  }

  const resolveUserRoleIcon = role => {
    if (role === 'subscriber') return 'UserIcon'
    if (role === 'author') return 'SettingsIcon'
    if (role === 'maintainer') return 'DatabaseIcon'
    if (role === 'editor') return 'Edit2Icon'
    if (role === 'super-admin') return 'ServerIcon'
    return 'UserIcon'
  }

  const resolveUserStatusVariant = status => {
    if (status === 'pending') return 'warning'
    if (status === 'active') return 'success'
    if (status === 'inactive') return 'secondary'
    return 'primary'
  }

  return {
    branchId,
    fetchInvoices,
    tableColumns,
    perPage,
    currentPage,
    startDate,
    endDate,
    totalInvoices,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refInvoicesListTable,
    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,
    refetchData,
    // Extra Filters
    roleFilter,
    statusFilter,
  }
}
